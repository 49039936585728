define("discourse/plugins/discourse-multilingual/discourse/services/tag-translations", ["exports", "@ember/service", "I18n", "ember"], function (_exports, _service, _I18n, _ember) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  function parsePipeSeparatedTranslations(translations) {
    if (!translations) {
      return {};
    }
    return translations.split("|").map(pair => pair.trim().split(":").map(str => str.trim())).reduce((acc, _ref) => {
      let [key, value] = _ref;
      if (key && value) {
        acc[key.replace(/"/g, "").trim()] = value.replace(/"/g, "").trim();
      }
      return acc;
    }, {});
  }
  var _default = _exports.default = _service.default.extend({
    siteSettings: (0, _service.inject)(),
    init() {
      this._super(...arguments);
      this.loadTranslations();
    },
    loadTranslations() {
      const siteSettings = this.get("siteSettings");
      try {
        const deTranslations = siteSettings.tag_translations_de ? parsePipeSeparatedTranslations(siteSettings.tag_translations_de) : {};
        const enTranslations = siteSettings.tag_translations_en ? parsePipeSeparatedTranslations(siteSettings.tag_translations_en) : {};
        const frTranslations = siteSettings.tag_translations_fr ? parsePipeSeparatedTranslations(siteSettings.tag_translations_fr) : {};
        _I18n.default.tag_translations = {
          de: deTranslations,
          en: enTranslations,
          fr: frTranslations
        };
      } catch (error) {
        console.error("Error parsing tag translations from settings:", error);
        _I18n.default.tag_translations = {
          de: {},
          en: {},
          fr: {}
        };
      }
    },
    siteSettingsObserver: _ember.default.observer("siteSettings.tag_translations_de", "siteSettings.tag_translations_en", "siteSettings.tag_translations_fr", function () {
      this.loadTranslations();
    })
  });
});