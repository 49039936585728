define("discourse/plugins/discourse-multilingual/discourse/lib/multilingual-tag", ["exports", "discourse/plugins/discourse-multilingual/discourse/lib/multilingual", "discourse/lib/utilities", "discourse-common/lib/helpers", "discourse-common/lib/get-url", "discourse/models/user", "I18n"], function (_exports, _multilingual, _utilities, _helpers, _getUrl, _user, _I18n) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.multilingualTagRenderer = multilingualTagRenderer;
  _exports.multilingualTagTranslator = multilingualTagTranslator;
  function multilingualTagRenderer(tag, params) {
    params = params || {};
    const siteSettings = (0, _helpers.helperContext)().siteSettings;
    const clt = (0, _multilingual.isContentLanguage)(tag, siteSettings);
    if (clt && !params.contentLanguageTag) {
      return "";
    }
    tag = (0, _utilities.escapeExpression)(tag).toLowerCase();
    const translatedTag = multilingualTagTranslator(tag);
    const visibleName = clt ? clt.name : translatedTag;
    const classes = ["discourse-tag"];
    const tagName = params.tagName || "a";
    let path;
    if (tagName === "a" && !params.noHref) {
      if ((params.isPrivateMessage || params.pmOnly) && _user.default.current()) {
        const username = params.tagsForUser ? params.tagsForUser : _user.default.current().username;
        path = `/u/${username}/messages/tags/${tag}`;
      } else {
        path = `/tag/${tag}`;
      }
    }
    const href = path ? ` href='${(0, _getUrl.default)(path)}' ` : "";
    if (siteSettings.tag_style || params.style) {
      classes.push(params.style || siteSettings.tag_style);
    }
    let val = "<" + tagName + href + " data-tag-name=" + tag + " class='" + classes.join(" ") + "'>" + visibleName + "</" + tagName + ">";
    if (params.count) {
      val += " <span class='discourse-tag-count'>x" + params.count + "</span>";
    }
    return val;
  }
  function multilingualTagTranslator(tag) {
    const locale = _I18n.default.currentLocale();
    if (_I18n.default.tag_translations && _I18n.default.tag_translations[locale] && _I18n.default.tag_translations[locale][tag]) {
      return _I18n.default.tag_translations[locale][tag];
    } else {
      return tag;
    }
  }
});