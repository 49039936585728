define("discourse/plugins/discourse-multilingual/discourse/lib/sidebar/user/tags-section/custom-base-tag-section-link", ["exports", "discourse-common/lib/escape", "I18n"], function (_exports, _escape, _I18n) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  _exports.registerCustomTagSectionLinkPrefixIcon = registerCustomTagSectionLinkPrefixIcon;
  _exports.resetCustomTagSectionLinkPrefixIcons = resetCustomTagSectionLinkPrefixIcons;
  let customTagSectionLinkPrefixIcons = {};
  function registerCustomTagSectionLinkPrefixIcon(_ref) {
    let {
      tagName,
      prefixValue,
      prefixColor
    } = _ref;
    customTagSectionLinkPrefixIcons[tagName] = {
      prefixValue,
      prefixColor
    };
  }
  function resetCustomTagSectionLinkPrefixIcons() {
    for (let key in customTagSectionLinkPrefixIcons) {
      if (customTagSectionLinkPrefixIcons.hasOwnProperty(key)) {
        delete customTagSectionLinkPrefixIcons[key];
      }
    }
  }
  class CustomBaseTagSectionLink {
    constructor(_ref2) {
      let {
        tag,
        currentUser
      } = _ref2;
      this.tag = tag;
      this.tagName = tag.name;
      this.currentUser = currentUser;
    }
    get name() {
      return this.tagName;
    }
    get text() {
      const translation = _I18n.default.tag_translations[_I18n.default.currentLocale()][this.tagName];
      return translation || this.tagName;
    }
    get title() {
      return (0, _escape.default)(this.tag.description);
    }
    get prefixType() {
      return "icon";
    }
    get prefixValue() {
      return customTagSectionLinkPrefixIcons[this.tagName]?.prefixValue || "tag";
    }
    get prefixColor() {
      return customTagSectionLinkPrefixIcons[this.tagName]?.prefixColor;
    }
  }
  _exports.default = CustomBaseTagSectionLink;
});