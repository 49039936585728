define("discourse/plugins/discourse-multilingual/discourse/lib/sidebar/user/tags-section/custom-pm-tag-section-link", ["exports", "discourse/plugins/discourse-multilingual/discourse/lib/sidebar/user/tags-section/custom-base-tag-section-link"], function (_exports, _customBaseTagSectionLink) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class CustomPMTagSectionLink extends _customBaseTagSectionLink.default {
    get models() {
      return [this.currentUser, this.tagName];
    }
    get route() {
      return "userPrivateMessages.tags.show";
    }
  }
  _exports.default = CustomPMTagSectionLink;
});