define("discourse/plugins/discourse-multilingual/discourse/helpers/translate-tag", ["exports", "@ember/component/helper", "I18n"], function (_exports, _helper, _I18n) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  _exports.translateTag = translateTag;
  function translateTag(_ref) {
    let [tagName] = _ref;
    return _I18n.default.tag_translations?.[_I18n.default.currentLocale()]?.[tagName] || tagName;
  }
  var _default = _exports.default = (0, _helper.helper)(translateTag);
});